import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
          light: {
            primary: '#53FAA5',
            secondary: '#3F44A6',
            dark_blue: '#212833',
            light_grey: '#F5F7FA',
            main_text_color: '#212833',
            text_contrast_to_background: '#FFFFFF',
            text_tertiary: '#475467',
            title_text_color: '#3F44A6',
            title_highlight_color: '#6B71F2',
            background_contrast: '#F7F9FF',
            primary_light: '#DAFBE5',
            hint_text_color: '#606A76',
            warning_prosk: '#FDB022'
          }
        }
      },
      typography: {
        fontFamily: 'Plus Jakarta Sans, sans-serif'
      }
    }
export default new Vuetify(opts);
